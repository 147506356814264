<template>
  <v-container fluid v-if="anno">
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <div v-if="loadinPage">
      <v-main class="mt-5">
        <v-row class="mb-6">
          <v-col cols="9">
            <h1 style="color: #1a237e">Verifica Rendiconto</h1>
          </v-col>
          <v-col cols="3"> <RiquadroProfiloRuolo /> </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <step-rendicontazioni :numero-step="1"></step-rendicontazioni>
        <RiepilogoGeneraleRendiconto
          v-if="rendiconto"
          :rendiconto="rendiconto"
          :udm="true"
          class="mt-3"
        />
      </v-main>
      <v-main class="mt-5">
        <RiepilogoFinanziario :rendiconto="rendiconto"></RiepilogoFinanziario>
      </v-main>
      <template>
        <div class="d-flex justify-end mt-3 my-3">
          <p class="mr-3">Percentuale di campionamento: 4%</p>
          <v-btn color="primary" @click="aperturaDialogConferma(1)">
            Estendi campione
          </v-btn>
        </div>
      </template>
      <v-main>
        <v-card>
          <v-data-table
            :items="certificatiCampionati"
            :headers="headerCertificati"
            :loading="loadingTableAttestati"
          >
            <!--eslint-disable -->
          <template v-slot:item.nomeCognome="{ item }">
            <v-card-text v-html="item.certificato.studente.nome + ' ' + item.certificato.studente.cognome + ''"/>
          </template>
          <template v-slot:item.estrattoDaAlgoritmo="{ item }">
            {{ item.estrattoDaAlgoritmo ? 'Si' : 'No' }}
          </template>
          <template v-slot:item.importoTariffa="{ item }">
            <v-card-text v-html="'16.67 €'"/>
          </template>
          <template v-slot:item.tipologiaEsitoVerificaCertificato="{ item }">
            <v-card-text v-if="item.tipologiaEsitoVerificaCertificato">
              {{ item.tipologiaEsitoVerificaCertificato.descrizione }}
            </v-card-text>
            <v-card-text v-else>
              In Attesa di verifica
            </v-card-text>
          </template>
            <template v-slot:item.scuolaStudente="{item}">
              <v-card-text v-if="rendiconto.anno === 2022" v-html="item.corsoCampionato.accordo.istituto.denominazioneScuola" />
              <v-card-text v-else v-html="item.certificato.studente.corsiStudente[0].istituto.denominazioneScuola" />
            </template>
          <template v-slot:item.importoAmmesso="{item}">
            <span v-if="item.importoAmmesso">
              {{ item.importoAmmesso }} €
            </span>
          </template>
            <template v-slot:item.controllatoDG="{item}">
            <span>
              {{ getCampionatoRup(item.certificato) }}
            </span>
            </template>
          <template v-slot:item.valoreAttribuito="{item}">
            {{ item.certificato.valoreAttribuito }} €
          </template>
          <template v-slot:item.importoTariffa="{item}">
            <span v-html="'16.67 €'"/>
          </template>
          <template v-slot:item.importoNonAmmesso="{item}">
            <v-card-text v-if="item.tipologiaEsitoVerificaCertificato">
            <span v-if="item.tipologiaEsitoVerificaCertificato.id === 2">
               {{ item.importoNonAmmesso ?? parseFloat(item.certificato.valoreAttribuito) }}€
            </span>
              <span v-else-if="item.tipologiaEsitoVerificaCertificato.id === 3">
              {{
                  item.importoNonAmmesso ?? parseFloat(item.certificato.valoreAttribuito - item.importoAmmesso).toFixed(2)
                }}€
            </span>
              <span v-else> 0 €</span>
            </v-card-text>

          </template>
          <template v-slot:item.azioni="{ item, index }">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="v-icon"
                    v-bind="attrs"
                    v-on="on"
                    :icon="true"
                >
                  <v-icon> mdi-dots-vertical</v-icon>
                </v-btn>
              </template><!-- eslint-enable -->
                <v-list>
                  <v-list-item-group color="primary">
                    <v-list-item
                      class="item-menu"
                      v-for="(itemMenu, i) in menuAction"
                      :key="i"
                      :disabled="i === 1 && item.disabledActionVerifica"
                      @click="gestioneMenuAzioniAttestato(item, i)"
                    >
                      {{ itemMenu }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
            <!-- eslint-enable-->
          </v-data-table>
          <v-card-actions>
            <v-dialog v-model="dialogVerificaAttestato">
              <ContenutoDialogVerifcaAttestato
                :attestato-campionato="attestatoInVerifica"
                @close-dialog="
                  dialogVerificaAttestato = false;
                  loadingTableAttestati = false;
                "
                :dialog.sync="dialogVerificaAttestato"
                @callback="verificaSingoloAttestato"
              />
            </v-dialog>
            <dialog-conferma
              :text-conferma="textDialogConferma"
              @callback="salvaVerificaRendiconto"
              :dialog.sync="dialogConferma"
              @close-dialog="dialogConferma = false"
            />
            <dialog-conferma
              v-model="dialogEsciDaStepVerifica"
              :dialog.sync="dialogEsciDaStepVerifica"
              @callback="esciDaStepVerifica"
              @close-dialog="dialogEsciDaStepVerifica = false"
              :text-conferma="'ATTENZIONE!<br /> Se si esce dalla verifica della seguente rendicontazione senza salvare ( estendere il campione o andare allo step successivo ), si perdono le modifiche fatte.<br /> Confermare la scelta?'"
            />
          </v-card-actions>
        </v-card>
      </v-main>
      <template>
        <v-main>
          <v-row>
            <v-col cols="4">
              <h3 class="d-flex align-center ml-2">
                Totale : {{ importiCalcolati.importoCalcolatoTotale }}€
              </h3>
            </v-col>
            <v-col cols="7">
              <h3>
                Tasso di errore del rendiconto di progetto:
                {{ importiCalcolati.percErrore ?? 0 }}%
              </h3>
              <v-alert type="info" class="w-25" max-width="900">
                Il tasso di errore viene salvato in memoria quando:
                <ul>
                  <br />
                  <li>
                    Tutti gli attestati da verificare sono stati valutati, il
                    tasso di errore risulta maggiore del 10% e si conferma
                    l'estensione del campione accedendo alla pagina di 'estendi
                    campione'. <br />
                    Una volta esteso il campione sarà calcolato un nuovo tasso
                    di errore sugli ulteriori attestati selezionati.
                  </li>
                  <br />
                  <li>
                    Tutti gli attestati da verificare sono stati valutati, il
                    tasso di errore risulta minore del 10% e si accede alla
                    checklist. <br />
                    Una volta proseguito non sarà più possibile effettuare
                    modifiche alla valutazione degli attestati verificati.
                  </li>
                </ul>
              </v-alert>
            </v-col>
            <v-col cols="3"> </v-col>
          </v-row>
        </v-main>
        <v-main>
          <div class="d-flex justify-space-around mb-6 bg-surface-variant">
            <v-sheet class="ma-2 pa-2">
              <v-btn @click="dialogEsciDaStepVerifica = true">
                <v-icon color="blue">mdi-arrow-left</v-icon>
                Esci dalla verifica del rendiconto
              </v-btn>
            </v-sheet>
            <v-sheet class="ma-2 pa-2">
              <a class="mr-6" @click="dialogRichiestaModifiche = true">
                <v-icon color="blue">mdi-plus</v-icon>
                Richiedi chiarimenti
              </a></v-sheet
            >
            <v-sheet class="ma-2 pa-2">
              <v-btn
                @click="aperturaDialogConferma(2)"
                :disabled="controlloDisabilitazione()"
                color="primary"
                >Compila checklist
              </v-btn>
              <v-alert type="info" class="ma-2 pa-2">
                Sarà possibile procedere alla compilazione della checklist sulla
                verifica del Rendiconto di progetto <br />
                solo dopo aver completato la verifica dei singoli attestati
                campionati e se il tasso di errore
                <br />
                del rendiconto di progetto è inferiore al 10%.</v-alert
              >
            </v-sheet>
          </div>
        </v-main>
        <v-dialog v-model="dialogRichiestaModifiche">
          <ContenutoDialogRichiestaModificheUdmComponent
            :rendiconto="this.rendiconto"
            @close-dialog="dialogRichiestaModifiche = false"
            :dialog.sync="dialogRichiestaModifiche"
            @richiesta-aggiunta="nuovaRichiesta += 1"
          />
        </v-dialog>
        <v-dialog
          v-model="dialogRiepilogoCorso"
          class="pa-4"
          persistent
          style="background-color: azure"
        >
          <DettaglioCorsoComponent
            :id-strutture="datiDialogRiepilogoCorso.idStrutture"
            :id-corso="datiDialogRiepilogoCorso.idCorso"
            :is-dialog="true"
            @callback="chiudiDialogRiepilogoCorso"
          />
        </v-dialog>
      </template>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
      <v-card-actions class="justify-center">
        <alert-component
          testo="Attendere: caricamento in corso"
          tipo-alert="info"
        />
      </v-card-actions>
    </div>
  </v-container>
  <v-container v-else>
    <alert-component
      class="text-center"
      testo="E' necessaria la selezione dell'anno accademico!"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import StepRendicontazioni from "@/components/Controlli/VerificheRendiconti/StepRendicontazioni.vue";
import RiepilogoGeneraleRendiconto from "@/components/Controlli/RiepilogoGeneraleRendicontoComponent.vue";
import ContenutoDialogVerifcaAttestato from "@/components/Controlli/VerificheRendiconti/ContenutoDialogVerificaAttestatoComponent.vue";
import ContenutoDialogRichiestaModificheUdmComponent from "@/components/Controlli/Udm/VerificheFormali/ContenutoDialogRichiestaModificheUdmComponent.vue";
// eslint-disable-next-line no-unused-vars
import RendicontiRepository from "@/api/ministero/RendicontiRepository";
import DialogConferma from "@/components/DialogConferma.vue";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import DettaglioCorsoComponent from "@/components/Controlli/DettaglioCorso/DettaglioCorsoComponent.vue";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo.vue";
import RiepilogoFinanziario from "@/components/Controlli/Udm/VerificheSostanziali/RiepilogoFinanziarioComponent.vue";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "verificaRendicontoUdmStepUno",
  components: {
    RiepilogoFinanziario,
    RiquadroProfiloRuolo,
    DettaglioCorsoComponent,
    DialogConferma,
    ContenutoDialogVerifcaAttestato,
    ContenutoDialogRichiestaModificheUdmComponent,
    RiepilogoGeneraleRendiconto,
    StepRendicontazioni,
    AlertComponent,
    BreadcrumbComponent,
  },
  computed: {
    ...mapState(["anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/udm/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista verbali di campionamento",
            disabled: false,
            to: `/scrivania/udm/controlli/lista-verbali`,
            exact: true,
            link: true,
          },
          {
            text: "Verifica Rendiconto",
            disabled: true,
            to: `/scrivania/controlli/verbali-campionamento-rendiconti/${this.idRendiconto}/verifiche`,
            exact: true,
            link: true,
          },
        ];
      }
    },
  },
  /* eslint-disable */
  watch: {
    anno() {
      this.initialize();
    },
    nuovaRichiesta() {
      setTimeout(() => {
        this.loadinPage = false;
        this.initialize();
      }, 6000);
    },
  certificatiCampionati: {
      handler: "checkAttestatiVerificati",
      immediate: true,
    },
  },
  data: () => ({
    loadinPage: false,
    nuovaRichiesta: 0,
    verbaleCampionamentoRendiconto: null,
    rendiconto: null,
    corsiCampionati: [],
    certificatiCampionati: [],
    idRendiconto: null,
    step: 1,
    loadingTableAttestati: true,
    headerCertificati: [
      {
        text: "Nome e Cognome",
        value: "nomeCognome",
      },
      {
        text: "Id corso",
        value: "corsoCampionato.id",
      },
      {
        text: "Istituto scolastico",
        value: "scuolaStudente",
      },
      {
        text: "Codice Fiscale",
        value: "certificato.studente.cf",
        sortable: false,
      },
      {text: "Estratto da Algoritmo", value: "estrattoDaAlgoritmo"},
      {
        text: "Totale ore",
        align: "center",
        value: "certificato.totaleOreStudente",
      },
      {
        text: "Importo tariffa",
        align: "center",
        value: "importoTariffa",
      },
      {text: "Valore Attribuito", value: "valoreAttribuito"},
      {
        text: "% ore freq",
        align: "center",
        value: "certificato.percentualeOreSvolte",
      },
      {text: "Controllato DG", value: "controllatoDG"},
      {text: "Esito", value: "tipologiaEsitoVerificaCertificato"},
      {text: "Importo Ammesso", value: "importoAmmesso"},
      {text: "Importo Non Ammesso", value: "importoNonAmmesso"},
      {text: "Azioni", value: "azioni"},
    ],
    menuAction: [
      "Dettaglio corso",
      "Verifica",
      "Dettagli Integrazione e modifiche",
      "Visualizza attestato",
    ],
    dialogVerificaAttestato: false,
    dialogRichiestaModifiche: false,
    dialogConferma: false,
    attestatoInVerifica: null,
    snackbar: {
      text: null,
      value: false,
      color: null,
    },
    checkAttestati: true,
    importoNonAmmesso: 0,
    importoTotale: 0,
    paginaSuccessiva: null,
    importiCalcolati: {
      importoAmmessoTotale: 0,
      importoCalcolatoTotale: 0,
      percErrore: 0,
      importoNonAmmesso: 0,
      totaleAttestatiDaVerificare: 0,
    },
    attestatiConVerifica: [],
    attestatiConImportiCalcolati: [],
    dialogRiepilogoCorso: false,
    dialogEsciDaStepVerifica: false,
    datiDialogRiepilogoCorso: {
      idCorso: null,
      idStrutture: null,
      idRendiconto: null,
    },
    textDialogConferma: "",
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.idRendiconto && this.anno) {
        this.idRendiconto = this.$route.params.idRendiconto;
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
          await ControlliUdmRepository.getSingoloVerbaleCampionamentoRendicontoUdm(
              parseInt(this.anno),
              this.idRendiconto,
              1
          );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        this.verificaRendiconto = this.rendiconto.verificaRendicontoUdm ?? null;
        this.corsiCampionati = this.verbaleCampionamentoRendiconto.corsiCampionati;
        if (this.verificaRendiconto && this.verificaRendiconto.importiCalcolatiVerificheRendiconti.length) {
          this.getAttestatiConImportiCalcolati(this.verificaRendiconto.importiCalcolatiVerificheRendiconti);
        } else {
          this.getAttestatiCampionati(
              this.corsiCampionati
          );
        }
        this.loadinPage = true;
      }
    },
    /**
     * verifico su quali attestati è necessario svolgere il calcolo degli importi e relativo tasso di errore
     */
    getAttestatiCampionati(corsiCampionati) {
      let attestatiCampionati = [];
      let totaleImporti = 0;
      let totaleAttestatiDaVerificare = 0;
      corsiCampionati.forEach((element) => {
        const arrCertificatiCampionatiCorso = element.certificatiCampionati;
        arrCertificatiCampionatiCorso.forEach((element1) => {
          element1.corsoCampionato = element.corsoOrientamento;

          // verifico se sull'attestato è già stato fatto il calcolo
          element1.disabledActionVerifica =  this.attestatiConImportiCalcolati.includes(element1.certificato.id);
          // sull'attestato ho già fatto una verifica
          if (!element1.disabledActionVerifica) {
            totaleImporti += parseFloat(element1.certificato.valoreAttribuito);
            totaleAttestatiDaVerificare += 1;
          }
          attestatiCampionati.push(element1);
        });
      });
      this.certificatiCampionati = attestatiCampionati;
      this.importiCalcolati.totaleAttestatiDaVerificare = totaleAttestatiDaVerificare;
      // devo verificare ancora attestati, allora totale e tasso di errore corrispondo ai valori rispettivi di somma dei valori attribuiti e importi non ammessi/totale *100
      if(totaleAttestatiDaVerificare > 0) {
        this.importiCalcolati.importoCalcolatoTotale = parseFloat(totaleImporti).toFixed(2);
        this.calcolaTassoErroreDiProgetto();
        this.loadingTableAttestati = false;
      } else {
        // ho tutti gli attestati verificati, di conseguenza recupero l'utlimo totale e tasso di errore calcolato
        this.getUltimiImportiCalcolatiVerificaRendiconto()
      }
    },
    gestioneMenuAzioniAttestato(item, index) {
      switch (index) {
        case 0:
          this.datiDialogRiepilogoCorso.idCorso = item.corsoCampionato.id;
          this.datiDialogRiepilogoCorso.idStrutture = item.corsoCampionato.idStrutture;
          this.datiDialogRiepilogoCorso.idRendiconto = this.rendiconto.id;
          this.dialogRiepilogoCorso = true;
          break;

        case 1:
          this.attestatoInVerifica = item;
          this.loadingTableAttestati = true;
          this.dialogVerificaAttestato = true;
          break;

        case 2:
          break;

        case 3:
          this.downloadAttestato(item);
      }
    },
    verificaSingoloAttestato(form, item) {
      this.loadingTableAttestati = true;
      item.motivazione = form.motivazione;
      item.tipologiaEsitoVerificaCertificato = {
        id: form.tipologiaEsitoVerificaCertificato.id,
        descrizione: form.tipologiaEsitoVerificaCertificato.descrizione
      };

      if (form.tipologiaEsitoVerificaCertificato.id === 1) {
        item.importoAmmesso = parseFloat(item.certificato.valoreAttribuito);
        item.importoNonAmmesso = 0;
      } else if (form.tipologiaEsitoVerificaCertificato.id === 2) {
        item.importoAmmesso = 0;
        item.importoNonAmmesso = parseFloat(item.certificato.valoreAttribuito);
      } else {
        item.importoAmmesso = parseFloat(form.importoAmmesso);
        item.importoNonAmmesso = parseFloat(item.certificato.valoreAttribuito - form.importoAmmesso).toFixed(2);
      }
      this.calcolaTassoErroreDiProgetto();

      const idCertificato = item.certificato.id;
      this.certificatiCampionati = this.certificatiCampionati.map((element) => {
        if (element.certificato.id === idCertificato) {
          element = item;
        }
        return element;
      });
      this.dialogVerificaAttestato = false;
      this.loadingTableAttestati = false;
    },

    async salvaVerificaRendiconto() {

      const idStrutture = this.rendiconto.idStrutture;
      const idRendiconto = this.rendiconto.id;
      const paginaSuccessiva = this.paginaSuccessiva;
      const percErroreCalcolata = this.importiCalcolati.percErrore;

      // recupero gli attestati che non sono ancora verificati al caricamento della pagina
      const attestatiDaVerificare = this.certificatiCampionati.filter((element) => element.disabledActionVerifica === false && element.tipologiaEsitoVerificaCertificato != null);
      // verifico se bisogna memorizzare su db il calcolo del tasso di errore e importi ammessi/non ammessi oppure no
      const verificaCalcoloTassoErrore = this.controlloSalvataggioCalcoloTassoErrore(attestatiDaVerificare, paginaSuccessiva, percErroreCalcolata);

      /**
       * 1)  calcolo tasso errore:caso in cui vado nella pagina di estendi campione:
       *    ->  devo avere tutti gli attestati verifica e il tasso di errore è maggiore di 10;
       *
       *
       * 2)  calcolo tasso errore nella compilazione delle checklist
       *    ->   se ho verificato tutti gli attestati e tasso errore inferiore del 10 %
       */

      const formBody = new FormData();
      formBody.append(
          "attestatiDaVerificare",
          JSON.stringify(attestatiDaVerificare)
      );

      if (this.rendiconto.verificaRendicontoUdm == null) {
        await RendicontiRepository.inserimentoVerificaRendicontoUdm(idStrutture, idRendiconto, formBody, verificaCalcoloTassoErrore).then((data) => {
          if (data) {
            this.aggiornaAttestatiCampionatiUdm(formBody);
          }
        }).catch((reason) => {
          this.setValueSnackbar(
              false,
              "Un errore server è stato riscontrato.",
              "red-darken-4"
          );
        });
      } else {
        const idVerifica = this.rendiconto.verificaRendicontoUdm.id;
        await RendicontiRepository.modificaVerificaRendicontoUdm(idStrutture, idRendiconto, idVerifica, 1, formBody, verificaCalcoloTassoErrore).then((data) => {
          if (data) {
            this.aggiornaAttestatiCampionatiUdm(formBody);
          }
        })
      }
    },
    goToNextPage() {
      if (this.paginaSuccessiva === "step2") {
        this.$router.push({
          name: "verificaAnagraficheRendicontoUdm",
          params: {idRendiconto: this.idRendiconto},
        });
      } else {
        this.verbaleCampionamentoRendiconto.importiCalcolati = this.importiCalcolati
        this.$router.push(
            {
              name: 'EstendiCampioneUdm',
              params: {
                idRendiconto: this.idRendiconto,
                rendicontoCampione: this.verbaleCampionamentoRendiconto,
                statoCampione: "salvato",

              }
            }
        );
      }
    },
    setValueSnackbar(esito, text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
    checkAttestatiVerificati(certificatiCampionati) {
      const attestatiNonVerificati = certificatiCampionati.filter(
          (item) => item.tipologiaEsitoVerificaCertificato == null
      );
      this.checkAttestati = attestatiNonVerificati.length > 0;
    },
    /**
     * Il calcolo verrà fatto solo sugli attestati che si stanno verificando attualmente
     * e non su quelli già verificati
     */
    calcolaTassoErroreDiProgetto() {
      let totale = 0;
      let importiNonAmmessi = 0;
      let importoAmmesso = 0;

      this.certificatiCampionati.forEach((element) => {

        const valoreAttribuito = parseFloat(element.certificato.valoreAttribuito);
        // solo per quelli che è possibile verificare
        if (!this.attestatiConImportiCalcolati.includes(element.certificato.id)) {
          totale += valoreAttribuito;
          if (element.tipologiaEsitoVerificaCertificato != null) {
            const esito = element.tipologiaEsitoVerificaCertificato;

            if (esito === 1) {
              importoAmmesso += valoreAttribuito;
            } else if (esito.id === 2) { // importo non ammesso
              importiNonAmmessi += valoreAttribuito;
            } else { // parzialmente ammesso
              importiNonAmmessi += valoreAttribuito - element.importoAmmesso;
              importoAmmesso += element.importoAmmesso;
            }
          }
        }
      });
      this.importiCalcolati.importoCalcolatoTotale = parseFloat(totale).toFixed(2);
      this.importiCalcolati.percErrore = parseFloat((importiNonAmmessi / totale) * 100).toFixed(2);
      this.importiCalcolati.importoAmmessoTotale = parseFloat(importoAmmesso).toFixed(2);
      this.importiCalcolati.importoNonAmmesso = parseFloat(importiNonAmmessi).toFixed(2);
    },
    controlloDisabilitazione() {
      return this.checkAttestati || this.importiCalcolati.percErrore > 10;
    },
    aperturaDialogConferma(tipo) {
      // salvo in una variabile la pagina successiva in cui andare quando il dialog di conferma operazione parte
      this.paginaSuccessiva = tipo === 1 ? 'estendiCampione' : 'step2';
      this.textDialogConferma = tipo === 1 ?
          "Attenzione! Si sta procedendo all'estensione del campione. <br />  <br />" +
          "Se il tasso di errore è maggiore del 10 % e tutti gli attestati sono stati verificati, una volta esteso il campione, <br />" +
          " non sarà più possibile modificare le valutazioni effettuate."
          :  "Attenzione! Si sta procedendo alla compilazione delle checklist di verifica. <br />  <br />" +
          "Se si procede, le valutazioni saranno effettive e non sarà più possibile modificarle.";
      this.dialogConferma = true;
    },
    async aggiornaAttestatiCampionatiUdm(formBody) {

      const anno = this.rendiconto.anno;
      const idVerbaleRendiconto = this.verbaleCampionamentoRendiconto.id;
      await ControlliUdmRepository.aggiornaAttestatiCampionatiUdm(
          anno,
          idVerbaleRendiconto,
          formBody
      ).then((data) => {
        this.goToNextPage();
      });
    },
    async downloadAttestato(item) {
      await MinisteroRepository.downloadFile(
          this.anno,
          item.certificato.fileReferenteIstituto.id,
          item.certificato.fileReferenteIstituto.nomeOriginale
      );
    },
    getCampionatoRup(item){
      return item.campionatoRup.length > 0 ? "Si" : "No";
    },
    getUltimiImportiCalcolatiVerificaRendiconto() {

      if (this.verificaRendiconto.importiCalcolatiVerificheRendiconti.length) {
        const arrImportiPrecedente = this.verificaRendiconto.importiCalcolatiVerificheRendiconti.sort((a, b) => {
          const dataA = new Date(a.dataCalcolo).getTime();
          const dataB = new Date(b.dataCalcolo).getTime();

          return dataB - dataA;
        });

        this.importiCalcolati.importoCalcolatoTotale = arrImportiPrecedente[0].importoTotaleCalcolato;
        this.importiCalcolati.percErrore = arrImportiPrecedente[0].tassoErrore;
        this.importiCalcolati.importoNonAmmesso = arrImportiPrecedente[0].importoNonAmmesso;
        this.importiCalcolati.importoAmmessoTotale = arrImportiPrecedente[0].importoAmmesso;
      }
    },
    /**
     * @param importiCalcolatiPrecedenti[]
     * @description Recupoero gli attestati che hanno avuto la verifica dell'attestato e
     * di cui ho già calcolato l'importo per la generazione di un tasso di errore.
     */
    getAttestatiConImportiCalcolati(importiCalcolatiPrecedenti) {
      let attestatiConImportoCalcolato = [];
      if (importiCalcolatiPrecedenti.length) {
        importiCalcolatiPrecedenti.forEach((element) => {
          if (element.certificatiCalcolati.length) {
            element.certificatiCalcolati.forEach((element1) => {
              attestatiConImportoCalcolato.push(element1.id_certificato);
            })
          }
        });
      }
      this.attestatiConImportiCalcolati = attestatiConImportoCalcolato;
      this.getAttestatiCampionati(this.corsiCampionati);
    },
    chiudiDialogRiepilogoCorso() {
      this.dialogRiepilogoCorso = false;
    },
    esciDaStepVerifica() {
      this.dialogEsciDaStepVerifica = false;
      this.$router.push({name: "ListaVerbaliRendicontiUdm"});
    },
    controlloSalvataggioCalcoloTassoErrore(attestatiDaVerificare, paginaSuccessiva, percErroreCalcolata) {
      let salvataggioCalcoloErrore = null;
      let controlloTassoErrore = this.importiCalcolati.percErrore > 10;
      switch(paginaSuccessiva) {
        case "estendiCampione":
          salvataggioCalcoloErrore = attestatiDaVerificare.length === this.importiCalcolati.totaleAttestatiDaVerificare && controlloTassoErrore;
          break;

        case "step2":
           salvataggioCalcoloErrore = attestatiDaVerificare.length === this.importiCalcolati.totaleAttestatiDaVerificare && !controlloTassoErrore;
          break;
      }

      return salvataggioCalcoloErrore;
    },
  },
};
</script>
