var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.rendiconti && _vm.rendiconti.length)?_c('v-main',{staticClass:"pa-0"},[_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"col":"8"}},[_c('v-text-field',{attrs:{"solo":"","append-icon":"mdi-magnify","label":"Cerca per ID Rendiconto, CUP, nome istituzione","hide-details":""},model:{value:(_vm.ricerca),callback:function ($$v) {_vm.ricerca=$$v},expression:"ricerca"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headersRendiconti,"search":_vm.ricerca,"items":_vm.rendiconti},scopedSlots:_vm._u([{key:"item.statoTesto",fn:function({ item }){return [_c('v-chip',{staticClass:"my-2",attrs:{"outlined":"","color":item.statoColore}},[_vm._v(" "+_vm._s(item.statoTesto)+" ")])]}},{key:"item.dataTrasmissione",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.dataTrasmissione ? _vm.getDateToString(item.dataTrasmissione) : 'Non disponibile')}})]}},{key:"item.azioni",fn:function({ item }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-icon",attrs:{"icon":true}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.menuAction),function(itemMenu,i){return _c('v-list-item',{key:i,staticClass:"item-menu",attrs:{"disabled":_vm.controlloDisabilitazione(
                    i,
                    item
                  )}},[_c('v-list-item-content',[(itemMenu.path)?_c('v-list-item-action',{on:{"click":function($event){_vm.goToNextPage(
                        itemMenu.path,
                        parseInt(item.idStrutture),
                        item.capofila,
                        parseInt(item.id),
                        itemMenu
                      )}}},[_vm._v(" "+_vm._s(itemMenu.text)+" ")]):_c('v-list-item-action',{domProps:{"innerHTML":_vm._s(itemMenu.text)},on:{"click":function($event){return _vm.gestioneClickItemMenu(i, item)}}})],1)],1)}),1)],1)],1)]}},{key:"item.importoRichiesto",fn:function( { item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formattaImporto(item.importoRichiesto ?? null)))])]}},{key:"item.importoAmmesso",fn:function( { item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formattaImporto(item.importoAmmesso ?? null)))])]}},{key:"item.importoAmmessoUdm",fn:function( { item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formattaImporto(item.importoAmmessoUdm ?? null)))])]}}],null,false,1984692700)})],1):_c('v-main',{staticClass:"text-center"},[_c('v-card',[_c('v-card-actions',{staticClass:"justify-center"},[_c('alert-component',{attrs:{"testo":"Non sono presenti rendiconti di progetto","tipo-alert":"info","width":800}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }