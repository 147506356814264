<template>
  <v-card elevation="4">
    <v-card-title> Riepilogo target </v-card-title>
    <v-card-text>
      <v-data-table :items="filteredTarget" :headers="headersTarget">
        <!-- eslint-disable -->
        <template v-slot:item.sommaImportoRendicontato="{ item }">
          <span v-html="getFormattedAmount(item.sommaImportoRendicontato)" />
        </template>
        <template v-slot:item.programmaOrientamento.assegnazioneFinanziaria="{ item }">
          <span v-html="getFormattedAmount(item.programmaOrientamento.assegnazioneFinanziaria + (item.assegnazioneAggiuntiva ? item.assegnazioneAggiuntiva : 0) ) " />
        </template>
        <template v-slot:item.targetStudenti="{ item }">
          {{getTargetStudenti(item.target_studenti, item.programmaOrientamento.numeroAlunniCoinvolti)}}
        </template>
        <template v-slot:item.avanzamentoTarget="{ item }">
          <span>
             {{ parseFloat((parseInt(item.totale_attestati_firmati) / parseInt(item.programmaOrientamento.numeroAlunniCoinvolti)) * 100).toFixed(2)}} %
          </span>
        </template>
        <!-- eslint-enable -->
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "riepilogoTreAssegnazioneRisorse",
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  watch: {
    idStrutture() {
      this.getFilteredData();
    },
    anno() {
      this.getFilteredData();
    },
  },
  components: {},
  props: {
    target: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.getFilteredData();
  },
  data: () => ({
    headersTarget: [
      { text: "CUP", value: "programmaOrientamento.cup" },
      { text: "ISTITUZIONE", value: "programmaOrientamento.nomeIstituzione" },
      {
        text: "ASSEGNAZIONE FINALE",
        value: "programmaOrientamento.assegnazioneFinanziaria",
      },
      { text: "IMPORTO RENDICONTATO", value: "sommaImportoRendicontato" },
      { text: "TARGET ACCORDI", value: "programmaOrientamento.numeroScuole" },
      { text: "ACCORDI SOTTOSCRITTI", value: "totale_accordi_sottoscritti" },
      { text: "TARGET CORSI", value: "programmaOrientamento.numeroCorsi" },
      { text: "CORSI EROGATI", value: "totale_corsi_erogati" },
      {
        text: "TARGET STUDENTI",
        value: "targetStudenti",
      },
      { text: "STUDENTI CON ATTESTATO", value: "totale_attestati_firmati" },
      { text: "AVANZAMENTO TARGET UE", value: "avanzamentoTarget" },
    ],
    filteredTarget: [],
    divisore: 1000000,
  }),
  methods: {
    getFilteredData() {
      this.filteredTarget = this.target.filter((element) => {
        return (
          (this.idStrutture != null
            ? element.programmaOrientamento.idStrutture === this.idStrutture
            : element.programmaOrientamento.idStrutture > 0) &&
          (this.anno != null
            ? element.programmaOrientamento.anno === this.anno
            : element.programmaOrientamento.anno > 0)
        );
      });
    },
    getFormattedAmount(importo) {
      if (!importo) return 0 + " €";
      return (
        parseFloat(importo).toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + " €"
      );
    },
    getTargetStudenti(incremento, alunni) {
      return incremento ? incremento + alunni : alunni;
    },
  },
};
</script>
