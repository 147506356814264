<template>
  <v-container fluid>
    <v-main class="pa-0" v-if="selezionabili">
      <v-row class="my-4">
        <v-col col="8">
          <v-text-field
            solo
            v-model="ricerca"
            append-icon="mdi-magnify"
            label="Cerca per ID Rendiconto, CUP, nome istituzione"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="px-4">
        <v-checkbox
          v-if="isRoleResponsabileControllo || isRoleConsulenza"
          v-model="selectAll"
          label="Seleziona tutto"
          @change="selectAllItems"
        />
      </div>
      <v-data-table
        :headers="headersRendiconti"
        :search="ricerca"
        :items="rendiconti"
        v-model="rendicontiSelezionati"
        :show-select="selezionabili"
        @input="handleChildVariables('input')"
        @keyup="handleChildVariables('keyup')"
        ><!--eslint-disable -->
        <template #header.data-table-select></template>
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
              v-if="isRoleResponsabileControllo || isRoleConsulenza"
              :value="isSelected"
              :readonly="item.assegnazioneControlloUdm !== null"
              :disabled="item.assegnazioneControlloUdm !== null"
              @input="select($event)"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.statoTesto="{ item }">
          <v-chip
              class="my-2"
              outlined
              :color="item.statoColore"
          >
            {{ item.statoTesto }}
          </v-chip>

        </template>
        <template v-slot:item.finale="{ item }">
          <v-chip outlined pill :color="colorTypeRendiconto(item.finale)"
                  v-html=" item.finale ? 'Finale' : 'Intermedio'"/>
        </template>
        <template v-slot:item.dataTrasmissione="{ item }">
          <span v-html="item.dataTrasmissione ? getDateToString(item.dataTrasmissione) : 'Non disponibile'" />
        </template>

        <!-- REGIS -->
        <template v-slot:item.inviatoRegis="{ item }">
          <v-edit-dialog
              :return-value.sync="item.inviatoRegis"
              large
              cancel-text="Annulla"
              save-text="Salva"
              persistent
              @save="salvaInvioRegis(newStatoRegis,item)"
          >

            {{ item.inviatoRegis ? 'Si' : 'No' }} <v-icon small>mdi-square-edit-outline</v-icon>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Modifica stato di invio
              </div>
              <v-select
                  v-model="newStatoRegis"
                  :items="statiRegis"
                  item-text="descrizione"
                  item-value="id"
                  :rules="rules.validazioneCampoNecessario"
                  label="Inviato *"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <!-- Fine Regis -->
        <template v-slot:item.azioni="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="v-icon"
                  v-bind="attrs"
                  v-on="on"
                  :icon="true"
              >
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item
                  class="item-menu"
                  v-for="(itemMenu, i) in menuAction"
                  :key="i"
                  :disabled="
                    controlloDisabilitazione(
                      i,
                      item,
                      item.stato,
                      item.assegnazioneControlloUdm ?? 0
                    )
                  "
                >
                  <v-list-item-content>
                    <v-list-item-action
                      v-if="itemMenu.path"
                      @click="
                        goToNextPage(
                          itemMenu.path,
                          parseInt(item.idStrutture),
                          item.capofila,
                          parseInt(item.id),
                          itemMenu
                        )
                      "
                    >
                      {{ itemMenu.text }}
                    </v-list-item-action>
                    <v-list-item-action
                      v-else
                      @click="gestioneClickItemMenu(i, item)"
                      v-html="itemMenu.text"
                    />
                  </v-list-item-content> </v-list-item
                >
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.importoRichiesto=" { item }">
          <span> {{ formattaImporto(item.importoRichiesto ?? null)}}</span>
        </template>
        <template v-slot:item.importoAmmesso=" { item }">
          <span> {{ formattaImporto(item.importoAmmesso ?? null)}}</span>
        </template>
        <template v-slot:item.importoAmmessoControlloFormale=" { item }">
          <span> {{ formattaImporto(item.importoAmmessoControlloFormale ?? null)}}</span>
        </template><!-- eslint-enable -->
      </v-data-table>
    </v-main>
    <v-main v-else>
      <v-data-table :headers="headersRendiconti" :items="rendiconti"
        ><!--eslint-disable -->
        <template v-slot:item.inviatoRegis="{ item }">
          <span v-html="item.inviatoRegis ? 'Si' : 'No'" />
        </template>
        <template v-slot:item.statoUdm="{ item }">
          {{ item.verificaFormaleRendiconto.esitoControllo ?? 'Da Verificare'}}
        </template>
        <template v-slot:item.finale="{ item }">
          <v-chip outlined pill :color="colorTypeRendiconto(item.finale)"
                  v-html=" item.finale ? 'Finale' : 'Intermedio'"/>
        </template>
        <template v-slot:item.dataTrasmissione="{ item }">
          <span v-html="item.dataTrasmissione ? getDateToString(item.dataTrasmissione) : 'Non disponibile'" />
        </template>
        <template v-slot:item.importoRichiesto=" { item }">
          <span> {{ formattaImporto(item.importoRichiesto ?? null)}}</span>
        </template>
        <template v-slot:item.importoAmmesso=" { item }">
          <span> {{ formattaImporto(item.importoAmmesso ?? null)}}</span>
        </template>
        <template v-slot:item.importoAmmessoControlloFormale=" { item }">
          <span> {{ formattaImporto(item.importoAmmessoControlloFormale ?? null)}}</span>
        </template>
        <!-- eslint-enable -->
      </v-data-table>
    </v-main>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "ListaRendicontiControlloFormaleComponent",
  components: {},
  props: {
    rendiconti: {
      type: Array,
      required: true,
    },
    selezionabili: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    ricerca: "",
    selectAll: false,
    assegnazioneEseguita: 0,
    rendicontiSelezionati: [],
    newStatoRegis: null,
    statiRegis: [
      { descrizione: "Si", id: 1 },
      { descrizione: "No", id: 0 },
    ],
    rules: {
      validazioneCampoNecessario: [(v) => v !== null || "Campo richiesto"],
    },
    menuAction: [
      {
        text: "Dettagli rendiconto",
        path: "/scrivania/controlli/lista-rendiconti/:idStrutture/:idRendiconto/dettaglioRendiconto",
      },
      {
        text: "Verifica rendiconto",
        path: `/scrivania/udm/controlli/controllo-formale-rendiconti/:idRendiconto/verifiche`,
      },
      {
        text: "Dettagli checklist UDM",
        path: "",
      },
      {
        text: "Dettaglio chiarimenti UDM",
        path: "",
      },
      {
        text: "Verbale di campionamento DG controlli",
        path: "",
      },
      {
        text: "Checklist DG controlli",
        path: "",
      },
      {
        text: "Verbale di esito  DG controlli",
        path: "",
      },
      {
        text: "Dettaglio integrazioni e modifiche DG controlli",
        path: "",
      },
    ],
  }),
  computed: {
    ...mapState(["user"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
    ]),
    headersRendiconti() {
      return [
        {
          text: "ID RENDICONTO",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "CUP", sortable: false, value: "programma.cup" },
        {
          text: "NOME ISTITUZIONE",
          value: "programma.nomeIstituzione",
        },
        {
          text: "PERIODO DI RIFERIMENTO",
          sortable: false,
          value: "quadrimestre.tipologiaQuadrimestre.descrizione",
        },
        {
          text: "IMPORTO RICHIESTO",
          sortable: false,
          value: "importoRichiesto",
        },
        {
          text: "TIPOLOGIA RENDICONTO",
          value: "finale",
        },
        {
          text: "DATA TRASMISSIONE",
          sortable: false,
          value: "dataTrasmissione",
        },
        {
          text: "ESITO ORGANO DI CONTROLLO",
          sortable: false,
          value: "stato.descrizione",
        },
        { text: "STATO UDM", sortable: true, value: "statoTesto" },
        { text: "INVIATO A REGIS", sortable: false, value: "inviatoRegis" },
        {
          text: "IMPORTO AMMESSO DG - CONTROLLI",
          sortable: false,
          value: "importoAmmesso",
        },
        {
          text: "IMPORTO AMMESSO UDM",
          sortable: true,
          value: "importoAmmessoControlloFormale",
        },
        { text: "AZIONI", sortable: false, value: "azioni" },
      ];
    },
  },
  watch: {
    assegnazioneEseguita() {
      this.initialize();
    },
  },
  methods: {
    selectAllItems() {
      if (this.selectAll) {
        // Seleziona tutti gli elementi quando "Seleziona tutto" è attivo
        let rendicontiNonAssegnati = [...this.rendiconti];
        this.rendicontiSelezionati = rendicontiNonAssegnati.filter(function (
          rendiconto
        ) {
          return rendiconto.assegnazioneControlloUdm === null;
        });
      } else {
        // Deseleziona tutti gli elementi quando "Seleziona tutto" è disattivo
        this.rendicontiSelezionati = [];
      }
    },
    controlloDisabilitazione(
      voceMenu,
      rendiconto,
      statoRendiconto,
      assegnazione
    ) {
      let disabilitato = true;
      switch (voceMenu) {
        case 0: // dettaglio rendiconto
          disabilitato = false;
          break;
        case 1: // verifica rendiconto formale
          if (rendiconto.verificaFormaleRendiconto) {
            if (
              rendiconto.verificaFormaleRendiconto.dataFineControllo &&
              rendiconto.verificaFormaleRendiconto.esitoControllo &&
              rendiconto.verificaFormaleRendiconto.fileVerificaCheklist
            ) {
              return true;
            }
          }
          disabilitato = !(
            this.isRoleIncaricatoControllo &&
            assegnazione &&
            assegnazione.incaricato &&
            assegnazione.incaricato.id === this.user.id &&
            assegnazione.statoAssegnazione &&
            assegnazione.statoAssegnazione.id === 1
          );
          //Su richiesta del MUR il caricamento del file per l'accettazione dell'incarico da parte del RdC è facoltativo!
          if (
            this.isRoleResponsabileControllo &&
            assegnazione &&
            assegnazione.incaricato &&
            assegnazione.incaricato.id === this.user.id
          ) {
            disabilitato = false;
          }
          //
          if (this.isRoleConsulenza) disabilitato = false;
          break;

        case 2: // dettagli checklist sempre pulsante abilitato
          disabilitato = !(
            rendiconto.verificaRendiconto &&
            rendiconto.verificaRendiconto.fileVerificaCheklist
          );
          break;
        case 3: // dettaglio chiarimenti UDM
          if (
            rendiconto.richiesteChiarimentiUdm &&
            rendiconto.richiesteChiarimentiUdm.length > 0
          ) {
            disabilitato = false;
          }
          break;
        case 4: // verbale Campionamento DG
        case 5: // verbale checklist DG
        case 6: // verbale esito DG
        case 7: // richieste Integrazioni DG Controlli
          disabilitato = false;
          break;
      }
      return disabilitato;
    },
    getDateToString(date) {
      return new Date(date).toLocaleDateString();
    },
    handleChildVariables(nameEvent) {
      this.$emit(nameEvent, this.rendicontiSelezionati);
    },
    goToNextPage(pathComponent, idStrutture, capofila, idRendiconto, itemMenu) {
      if (itemMenu.text === "Dettaglio integrazioni e modifiche") {
        this.$router.push({
          name: "dettagliIntegrazioniModifiche",
          params: { idRendiconto: idRendiconto },
          query: { readOnly: true },
        });
      } else {
        let replacements = [
          { search: ":idRendiconto", replace: idRendiconto },
          { search: ":idStrutture", replace: idStrutture },
        ];
        let result = pathComponent;
        replacements.forEach((replacement) => {
          result = result.replace(replacement.search, replacement.replace);
        });
        this.$router.push({
          path: result,
        });
      }
    },
    async gestioneClickItemMenu(indexMenu, itemRendiconto) {
      if (indexMenu === 2) {
        // richiamo l'api che mi scarica il file delle checklist;
        const idFileVerificaChecklist =
          itemRendiconto.verificaFormaleRendiconto.fileVerificaCheklist.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaFormaleRendiconto.fileVerificaCheklist
            .nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaChecklist,
          nomeFile
        );
      }
      if (indexMenu === 3) {
        this.$router.push({
          name: "dettagliChiarimentiUdmFormale",
          params: { idRendiconto: itemRendiconto.id },
          query: { readOnly: true },
        });
      }
      if (indexMenu === 4) {
        // richiamo l'api che mi scarica il file del verbale campio DG;
        const idFileVerbale = itemRendiconto.idFileVerbale;
        const anno = itemRendiconto.anno;
        const nomeFile = "Verbale_Campionamento";
        await MinisteroRepository.downloadFile(anno, idFileVerbale, nomeFile);
      }
      if (indexMenu === 5) {
        // richiamo l'api che mi scarica il file delle checklist;
        const idFileVerificaChecklist =
          itemRendiconto.verificaRendiconto.fileVerificaCheklist.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaRendiconto.fileVerificaCheklist.nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaChecklist,
          nomeFile
        );
      }
      if (indexMenu === 6) {
        // richiamo l'api che mi scarica il file dell'esito verifica DG
        const idFileVerificaRendiconto =
          itemRendiconto.verificaRendiconto.fileVerificaRendiconto.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaRendiconto.fileVerificaRendiconto
            .nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaRendiconto,
          nomeFile
        );
      }
      if (indexMenu === 7) {
        this.$router.push({
          name: "dettagliIntegrazioniModifiche",
          params: { idRendiconto: itemRendiconto.id },
          query: { readOnly: true },
        });
      }
    },
    async salvaInvioRegis(newStatoRegis, rendiconto) {
      console.log(newStatoRegis);
      console.log(rendiconto);
      try {
        await ControlliUdmRepository.salvaInvioRegis(
          rendiconto.anno,
          newStatoRegis,
          rendiconto.id
        );
        this.$emit("cambio-stato-regis", true);
      } catch (e) {
        this.$emit("cambio-stato-regis", false);
      }
    },
    formattaImporto(importo) {
      if (importo) {
        return new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(importo);
      }
      return "";
    },
    colorTypeRendiconto(isRendicontoFinale) {
      return isRendicontoFinale ? "primary" : "purple darken-2";
    },
  },
};
</script>
