<template>
  <v-container fluid>
    <v-main class="pa-0" v-if="rendiconti && rendiconti.length">
      <v-row class="my-4">
        <v-col col="8">
          <v-text-field
            solo
            v-model="ricerca"
            append-icon="mdi-magnify"
            label="Cerca per ID Rendiconto, CUP, nome istituzione"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headersRendiconti"
        :search="ricerca"
        :items="rendiconti"
        ><!--eslint-disable -->
        <template v-slot:item.statoTesto="{ item }">
          <v-chip
              class="my-2"
              outlined
              :color="item.statoColore"
          >
            {{ item.statoTesto }}
          </v-chip>

        </template>
        <template v-slot:item.dataTrasmissione="{ item }">
          <span v-html="item.dataTrasmissione ? getDateToString(item.dataTrasmissione) : 'Non disponibile'" />
        </template>
        <template v-slot:item.azioni="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="v-icon"
                  v-bind="attrs"
                  v-on="on"
                  :icon="true"
              >
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item
                  class="item-menu"
                  v-for="(itemMenu, i) in menuAction"
                  :key="i"
                  :disabled="
                    controlloDisabilitazione(
                      i,
                      item
                    )
                  "
                >
                  <v-list-item-content>
                    <v-list-item-action
                      v-if="itemMenu.path"
                      @click="
                        goToNextPage(
                          itemMenu.path,
                          parseInt(item.idStrutture),
                          item.capofila,
                          parseInt(item.id),
                          itemMenu
                        )
                      "
                    >
                      {{ itemMenu.text }}
                    </v-list-item-action>
                    <v-list-item-action
                      v-else
                      @click="gestioneClickItemMenu(i, item)"
                      v-html="itemMenu.text"
                    />
                  </v-list-item-content> </v-list-item
                >
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.importoRichiesto=" { item }">
          <span> {{ formattaImporto(item.importoRichiesto ?? null)}}</span>
        </template>
        <template v-slot:item.importoAmmesso=" { item }">
          <span> {{ formattaImporto(item.importoAmmesso ?? null)}}</span>
        </template>
        <template v-slot:item.importoAmmessoUdm=" { item }">
          <span> {{ formattaImporto(item.importoAmmessoUdm ?? null)}}</span>
        </template><!-- eslint-enable -->
      </v-data-table>
    </v-main>
    <v-main v-else class="text-center">
      <v-card>
        <v-card-actions class="justify-center">
          <alert-component
            testo="Non sono presenti rendiconti di progetto"
            tipo-alert="info"
            :width="800"
          />
        </v-card-actions>
      </v-card>
    </v-main>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import AlertComponent from "@/components/Common/AlertComponent.vue";

export default {
  name: "RepoRendicontiSostanzialeUdmComponent",
  components: { AlertComponent },
  props: {
    rendiconti: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    ricerca: "",
    assegnazioneEseguita: 0,
    newStatoRegis: null,
    statiRegis: [
      { descrizione: "Si", id: 1 },
      { descrizione: "No", id: 0 },
    ],
    rules: {
      validazioneCampoNecessario: [(v) => v !== null || "Campo richiesto"],
    },
    headersRendiconti: [
      {
        text: "ID RENDICONTO",
        align: "start",
        sortable: true,
        value: "id",
      },
      { text: "CUP", sortable: true, value: "programma.cup" },
      {
        text: "NOME ISTITUZIONE",
        sortable: false,
        value: "programma.nomeIstituzione",
      },
      {
        text: "PERIODO DI RIFERIMENTO",
        sortable: false,
        value: "quadrimestre.tipologiaQuadrimestre.descrizione",
      },
      { text: "IMPORTO RICHIESTO", sortable: false, value: "importoRichiesto" },
      { text: "DATA TRASMISSIONE", sortable: false, value: "dataTrasmissione" },
      {
        text: "ESITO ORGANO DI CONTROLLO",
        sortable: false,
        value: "stato.descrizione",
      },
      { text: "STATO UDM", sortable: true, value: "statoTesto" },
      {
        text: "IMPORTO AMMESSO DG - CONTROLLI",
        sortable: false,
        value: "importoAmmesso",
      },
      {
        text: "IMPORTO AMMESSO UDM",
        sortable: true,
        value: "importoAmmessoUdm",
      },
      { text: "AZIONI", sortable: false, value: "azioni" },
    ],
    menuAction: [
      {
        text: "Dettagli rendiconto",
        path: "/scrivania/controlli/lista-rendiconti/:idStrutture/:idRendiconto/dettaglioRendiconto",
      },
      {
        text: "Dettagli checklist UDM",
        path: "",
      },
      {
        text: "Dettaglio chiarimenti UDM",
        path: "",
      },
      {
        text: "Dettaglio verbale di esito UDM",
        path: "",
      },
      /*{
        text: "Verbale di campionamento DG controlli",
        path: "",
      },*/
      {
        text: "Checklist DG controlli",
        path: "",
      },
      {
        text: "Verbale di esito  DG controlli",
        path: "",
      },
      {
        text: "Dettaglio integrazioni e modifiche DG controlli",
        path: "",
      },
    ],
  }),
  computed: {
    ...mapState(["user", "anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
    ]),
  },
  watch: {
    assegnazioneEseguita() {
      this.initialize();
    },
  },
  methods: {
    controlloDisabilitazione(voceMenu, rendiconto) {
      let disabilitato = true;

      switch (voceMenu) {
        case 0: // dettaglio rendiconto
          disabilitato = false;
          break;

        case 1: // dettagli verbale
          disabilitato = !(
            rendiconto.verificaRendiconto &&
            rendiconto.verificaRendiconto.fileVerificaCheklist
          );
          break;

        case 2: // dettagli checklist sempre pulsante abilitato
          if (
            rendiconto.richiesteChiarimentiUdm &&
            rendiconto.richiesteChiarimentiUdm.length > 0
          ) {
            disabilitato = false;
          }
          break;
        case 3: // verbale Esito UDM
          disabilitato = !(
            rendiconto.verificaRendicontoUdm &&
            rendiconto.verificaRendicontoUdm.fileVerificaRendiconto
          );
          break;
        case 4: // verbale Campionamento DG
        case 5: // verbale checklist DG
        case 6: // verbale esito DG
          disabilitato = false;
          break;
      }

      return disabilitato;
    },
    getDateToString(date) {
      return new Date(date).toLocaleDateString();
    },
    handleChildVariables(nameEvent) {
      this.$emit(nameEvent, this.rendicontiSelezionati);
    },
    goToNextPage(pathComponent, idStrutture, capofila, idRendiconto, itemMenu) {
      if (itemMenu.text === "Dettaglio integrazioni e modifiche") {
        this.$router.push({
          name: "dettagliIntegrazioniModifiche",
          params: { idRendiconto: idRendiconto },
          query: { readOnly: true },
        });
      } else {
        let replacements = [
          { search: ":idRendiconto", replace: idRendiconto },
          { search: ":idStrutture", replace: idStrutture },
        ];
        let result = pathComponent;
        replacements.forEach((replacement) => {
          result = result.replace(replacement.search, replacement.replace);
        });

        this.$router.push({
          path: result,
        });
      }
    },
    async gestioneClickItemMenu(indexMenu, itemRendiconto) {
      if (indexMenu === 1) {
        // richiamo l'api che mi scarica il file delle checklist;
        const idFileVerificaChecklist =
          itemRendiconto.verificaRendicontoUdm.fileVerificaCheklist.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaRendicontoUdm.fileVerificaCheklist
            .nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaChecklist,
          nomeFile
        );
      }
      if (indexMenu === 2) {
        this.$router.push({
          name: "chiarimentiUdm",
          params: { idRendiconto: itemRendiconto.id },
          query: { readOnly: true },
        });
      }
      if (indexMenu === 3) {
        // richiamo l'api che mi scarica il file del verbale esito UDM;
        const idFileVerificaChecklist =
          itemRendiconto.verificaRendicontoUdm.fileVerificaRendiconto.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaRendicontoUdm.fileVerificaRendiconto
            .nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaChecklist,
          nomeFile
        );
      }
      /*if (indexMenu === 4) {
        // richiamo l'api che mi scarica il file del verbale campio DG;
        const idFileVerbale = itemRendiconto.idFileVerbale;
        const anno = itemRendiconto.anno;
        const nomeFile = "Verbale_Campionamento";
        await MinisteroRepository.downloadFile(anno, idFileVerbale, nomeFile);
      }*/
      if (indexMenu === 4) {
        // richiamo l'api che mi scarica il file delle checklist;
        const idFileVerificaChecklist =
          itemRendiconto.verificaRendiconto.fileVerificaCheklist.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaRendiconto.fileVerificaCheklist.nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaChecklist,
          nomeFile
        );
      }
      if (indexMenu === 5) {
        // richiamo l'api che mi scarica il file dell'esito verifica DG
        const idFileVerificaRendiconto =
          itemRendiconto.verificaRendiconto.fileVerificaRendiconto.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaRendiconto.fileVerificaRendiconto
            .nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaRendiconto,
          nomeFile
        );
      }
      if (indexMenu === 6) {
        this.$router.push({
          name: "dettagliIntegrazioniModifiche",
          params: { idRendiconto: itemRendiconto.id },
          query: { readOnly: true },
        });
      }
    },
    formattaImporto(importo) {
      if (importo) {
        return new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(importo);
      }
      return "";
    },
  },
};
</script>
