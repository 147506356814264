var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-main',[_c('BreadcrumbComponent',{attrs:{"breadcrumbs":_vm.breadcrumbs}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('h1',[_vm._v("Lista Verbali di Campionamento")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('RiquadroProfiloRuolo')],1),_c('v-spacer')],1),(_vm.loadingPage)?_c('v-row',{staticClass:"my-6"},[_c('v-col',{staticClass:"d-flex flex-row-reverse",attrs:{"cols":"12"}},[(_vm.isRoleResponsabileControllo || _vm.isRoleConsulenza)?[_c('router-link',{staticClass:"v-btn primary pa-2",attrs:{"to":{
            name: 'nuovoVerbaleControlloUdm',
          }}},[_c('v-icon',{staticClass:"white--text",attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Nuovo controllo ")],1)]:_vm._e()],2)],1):_vm._e(),(_vm.loadingPage)?_c('div',[(_vm.verbaliControlli && _vm.verbaliControlli.length)?_c('v-card',{attrs:{"elevation":"5","loading":_vm.loadingPage}},[_c('v-expansion-panels',{attrs:{"accordion":""}},_vm._l((_vm.verbaliControlli),function(verbale,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{staticClass:"white--text",attrs:{"color":"blue"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-card-text',[_vm._v(" Id Verbale "),_c('br'),_c('br'),_c('b',[_vm._v(" "+_vm._s(verbale.id)+" ")])])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card-text',[_vm._v(" Periodo di riferimento delle attività dal "),_c('br'),_c('br'),_c('b',[_vm._v(" "+_vm._s(verbale.dataInizioAttivita ? _vm.getTextDate(verbale.dataInizioAttivita) : "Non Disponibile")+" al "+_vm._s(verbale.dataFineAttivita ? _vm.getTextDate(verbale.dataFineAttivita) : " Non disponibile")+" ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card-text',[_vm._v(" Data Creazione Campione "),_c('br'),_c('br'),_c('b',[_vm._v(" "+_vm._s(_vm.getTextDate(verbale.dataCreazione))+" ")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-card-text',{staticClass:"text-white"},[_c('br'),_c('router-link',{staticClass:"text-white",staticStyle:{"color":"white"},attrs:{"to":{
                      name: 'VerbaleCampionamentoUdm',
                      params: {
                        anno: _vm.anno,
                        idVerbale: verbale.id,
                        readOnly: _vm.isVerbaleDaCompilare(verbale),
                      },
                    }}},[_vm._v(" Dettaglio verbale "),_c('v-icon',{staticClass:"mx-2",attrs:{"dark":""}},[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1),_c('v-expansion-panel-content',[_c('v-btn',{staticClass:"ma-3",on:{"click":function($event){return _vm.esportaSuExcel(
                  verbale.id,
                  verbale.verbaleCampionamentoRendiconto
                )}}},[_vm._v(" Esporta su excel "),_c('v-icon',[_vm._v(" mdi-file-excel ")])],1),_c('ListaRendicontiControlloSostanzialeUdmComponent',{key:_vm.assegnazioneEseguita,attrs:{"selezionabili":true,"rendiconti":_vm.getRendicontiVerbale(verbale.verbaleCampionamentoRendiconto)},on:{"keyup":_vm.setRendicontiSelezionati,"input":_vm.setRendicontiSelezionati,"cambio-stato-regis":_vm.changeStatoRegis}}),_c('v-card-actions',[(_vm.rendicontiSelezionati && _vm.rendicontiSelezionati.length)?_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row-reverse",attrs:{"cols":"12"}},[(_vm.isRoleResponsabileControllo || _vm.isRoleConsulenza)?_c('dialog-assegna-incarichi-udm',{attrs:{"rendiconti-selezionati":_vm.rendicontiSelezionati},on:{"assegnazione-eseguita":function($event){_vm.assegnazioneEseguita += 1}}}):_vm._e()],1)],1):_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row-reverse",attrs:{"cols":"12"}},[(_vm.isRoleResponsabileControllo || _vm.isRoleConsulenza)?_c('v-btn',{staticClass:"primary",attrs:{"disabled":true,"text":""}},[_vm._v(" Assegna incarico ")]):_vm._e()],1)],1)],1)],1)],1)}),1)],1):_c('v-card',{staticClass:"text-center"},[_c('v-card-actions',{staticClass:"justify-center"},[_c('alert-component',{attrs:{"testo":"Non è stato creato alcun verbale di controllo","tipo-alert":"info","width":800}})],1)],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":3000,"shaped":"","color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }