var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BreadcrumbComponent',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('h1',{staticStyle:{"padding-top":"25px"}},[_vm._v("Lista Rendiconti")]),_c('p',[_vm._v(" L'utente cliccando sul tasto estrai campione potrà campionare gli attestati all'interno dei rendiconti da controllare ")]),(_vm.loadingRendiconti)?_c('v-card',{staticClass:"px-4 pb-4",attrs:{"elevation":"4"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.rendiconti,"items-per-page":10,"single-select":false,"show-select":true,"loading-text":"Caricamento dati in corso..."},scopedSlots:_vm._u([{key:"item.cup",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.programma.cup))])]}},{key:"item.nomeIstituzione",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.programma.nomeIstituzione))])]}},{key:"item.quadrimestre",fn:function({ item }){return [_vm._v(" "+_vm._s(item.quadrimestre.tipologiaQuadrimestre.descrizione)+" ")]}},{key:"item.dataTrasmissione",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.dataTrasmissione)))])]}},{key:"item.importoRichiesto",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.importoRichiesto))+" €")])]}},{key:"item.actions",fn:function({ item }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-icon",attrs:{"icon":true}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menuAction),function(itemMenu,i){return _c('v-list-item',{key:i,staticClass:"item-menu"},[_c('router-link',{staticClass:"v-btn",attrs:{"to":{
                        name: itemMenu.nameComponent,
                        params: {
                              // capofila: capofila,
                              idRendiconto: Number(item.id),
                              idStrutture: Number(item.idStrutture),
                            },
                          }}},[_vm._v(" "+_vm._s(itemMenu.text)+" "),_c('v-icon',[_vm._v(" "+_vm._s(itemMenu.icon))])],1)],1)}),1)],1)]}}],null,false,2304427428),model:{value:(_vm.rendicontiSelezionati),callback:function ($$v) {_vm.rendicontiSelezionati=$$v},expression:"rendicontiSelezionati"}})],1),(_vm.rendiconti && _vm.rendiconti.length)?_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('alert-component',{attrs:{"testo":"La procedura di generazione potrebbe richiedere alcuni secondi","type":"warning"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"float-end",attrs:{"disabled":_vm.disabilitazionePulsanteEstraiCampione,"color":"primary"},domProps:{"innerHTML":_vm._s('Estrai Campione')},on:{"click":_vm.estraiCampione}}),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.value),callback:function ($$v) {_vm.$set(_vm.snackbar, "value", $$v)},expression:"snackbar.value"}},[_vm._v(" "+_vm._s(_vm.snackbar.testo)+" ")])],1)],1)],1):_c('v-card-actions',[_c('alert-component',{attrs:{"testo":"Impossibile generare il campione. Tutti i rendiconti sono stati associati a un verbale e sono in fase di verifica.","type":'info'}})],1)],1):_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"blue"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }