import Repository from "../../Repository";
export default {
  async getListaRendicontiControllati(anno) {
    const url = `api/ministero/udm/anno/${anno}/lista-rendiconti-controllati`;
    return (await Repository.get(url)).data;
  },
  async generaVerbaleCampionamentoUdm(anno, salvataggio, formBody) {
    const url = `api/ministero/udm/anno/${anno}/verbali-campionamento-udm?salvataggio=${salvataggio}`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async getVerbaliControlliRendicontiUdm(anno) {
    const url = `api/ministero/udm/anno/${anno}/verbali-campionamento-udm`;
    return (await Repository.get(url)).data;
  },
  async getVerbaliControlliFormaliRendicontiUdm(anno) {
    const url = `api/ministero/udm/anno/${anno}/verbali-controlli-formali-udm`;
    return (await Repository.get(url)).data;
  },
  async getListaFormaliRendicontiUdm(anno) {
    const url = `api/ministero/udm/anno/${anno}/lista-rendiconti-formali-udm`;
    return (await Repository.get(url)).data;
  },
  async assegnaIncaricoUdm(anno, idUtente, data) {
    const url = `api/ministero/udm/anno/${anno}/assegna-incarico-controllo-udm/${idUtente}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getAssegnazioniIncaricoUdm(anno) {
    const url = `api/ministero/udm/anno/${anno}/assegnazioni-incarichi-udm`;
    return (await Repository.get(url)).data;
  },
  async accettaIncaricoUdm(anno, data) {
    const url = `api/ministero/udm/anno/${anno}/accetta-incarico-controllo-udm`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getSingoloVerbaleControlliFormaliUdm(anno, idRendiconto, stepVerifica) {
    const url = `api/ministero/udm/anno/${anno}/verbali-controlli-formali-udm/${idRendiconto}?stepVerifica=${stepVerifica}`;
    return (await Repository.get(url)).data;
  },
  async terminaVerificaFormale(anno, idRendiconto, idVerifica) {
    const url = `api/ministero/udm/anno/${anno}/${idRendiconto}/termina-verifica-formale/${idVerifica}`;
    return (await Repository.post(url)).data;
  },
  async getSingoloVerbaleCampionamentoUdm(anno, idVerbale) {
    const url = `api/ministero/udm/anno/${anno}/verbale-campionamento-udm/${idVerbale}`;
    return (await Repository.get(url)).data;
  },
  async getListaRendicontiVerbaleUdm(anno, idVerbale) {
    const url = `api/ministero/udm/anno/${anno}/popolazione-corsi-udm/${idVerbale}`;
    return (await Repository.get(url)).data;
  },
  async getVerbaleCampioneUdmEstrattoScelto(anno, idVerbale, TipoCampione) {
    const url = `api/ministero/udm/anno/${anno}/verbale-campione-udm-estratto-scelto/${idVerbale}/${TipoCampione}`;
    return (await Repository.get(url)).data;
  },
  async salvaVerbaleCampionamentoUdm(anno, idVerbale, data) {
    const url = `api/ministero/udm/anno/${anno}/update-verbale-campionamento-udm/${idVerbale}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async createNuovaRichiestaChiarimentiUdm(anno, idRendiconto, data) {
    const url = `api/ministero/udm/anno/${anno}/${idRendiconto}/nuova-richiesta-chiarimenti`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getFileRispostaRichiestaUdm(
    idStrutture,
    richiesta,
    nomeFileOriginale,
    tipoAllegato
  ) {
    const url = `api/generici/${idStrutture}/rendiconti/${richiesta}/file-allegato-udm?tipoAllegato=${tipoAllegato}`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async updateRichiestaUdm(anno, richiesta, formBody) {
    const url = `api/ministero/udm/anno/${anno}/update-richiesta-chiarimento-udm/${richiesta}`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async downloadTemplatePdfVerbale(anno, data) {
    const url = `api/ministero/udm/anno/${anno}/genera-verbale-udm-pdf`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, data, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "PDF_Verbale_campionamento.pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async eliminaAssegnazioneUdm(anno, idAssegnazione) {
    const url = `api/ministero/udm/anno/${anno}/elimina-assegnazione-udm/${idAssegnazione}`;
    return (await Repository.delete(url)).data;
  },
  async getSingoloVerbaleCampionamentoRendicontoUdm(
    anno,
    idRendiconto,
    stepVerifica
  ) {
    const url = `api/ministero/udm/anno/${anno}/verbali-campionamento-rendiconti-udm/${idRendiconto}?stepVerifica=${stepVerifica}`;
    return (await Repository.get(url)).data;
  },
  async getRiepilogoFinanziario(anno, idRendiconto) {
    const url = `api/ministero/udm/anno/${anno}/riepilogo-finanziario-udm/${idRendiconto}`;
    return (await Repository.get(url)).data;
  },
  async aggiornaAttestatiCampionatiUdm(anno, idVerbaleRendiconto, formBody) {
    const url = `api/ministero/udm/anno/${anno}/verbali-campionamento-rendiconti-udm/${idVerbaleRendiconto}/corsi-campionati/certificati`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async estendiCampioneVerbaleRendicontoUdm(
    anno,
    idVerbaleRendiconto,
    formBody
  ) {
    const url = `api/ministero/udm/anno/${anno}/verbali-campionamento-rendiconti-udm/${idVerbaleRendiconto}/corsi-attestati-campionati-udm`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async getVerbaleCampioneUdmByRendiconto(anno, idRendiconto) {
    const url = `api/ministero/udm/anno/${anno}/verbale-primario-udm/${idRendiconto}`;
    return (await Repository.get(url)).data;
  },
  async salvaVerbaleEstensioneUdm(anno, idVerbale, firma, data) {
    const url = `api/ministero/udm/anno/${anno}/update-verbale-estensione-udm/${idVerbale}/?firma=${firma}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getTemplatePdfVerbaleEstensioneUdm(anno, idRendiconto, idVerbale) {
    const url = `api/ministero/udm/anno/${anno}/rendiconto/${idRendiconto}/verbale-estensione-udm-file/${idVerbale}`;
    const headers = { "Content-type": "multipart/form-data" };

    const res = await Repository.get(url, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "pdf_verbale_estensione_udm" + idVerbale + ".pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async eliminaFileVerbaleEstensioneUdm(anno, idVerbale) {
    const url = `api/ministero/udm/anno/${anno}/delete-file-verbale-estensione-udm/${idVerbale}`;
    return (await Repository.delete(url)).data;
  },
  async updateVerificaRendicontoUdm(anno, idVerifica, firma, data) {
    const url = `api/ministero/udm/anno/${anno}/update-verifica-rendiconto-udm/${idVerifica}/?firma=${firma}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getTemplatePdfVerbaleEsitoUdm(anno, idRendiconto, idVerbale) {
    const url = `api/ministero/udm/anno/${anno}/rendiconto/${idRendiconto}/verbale-esito-file-udm/${idVerbale}`;
    const headers = { "Content-type": "multipart/form-data" };

    const res = await Repository.get(url, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "pdf_verbale_esito_rendiconto" + idVerbale + ".pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async eliminaFileVerbaleEsitoUdm(anno, idVerifica) {
    const url = `api/ministero/udm/anno/${anno}/delete-file-verbale-esito-udm/${idVerifica}`;
    return (await Repository.delete(url)).data;
  },
  async terminaVerificaUdm(anno, idRendiconto, idVerifica) {
    const url = `api/ministero/udm/anno/${anno}/${idRendiconto}/termina-verifica-udm/${idVerifica}`;
    return (await Repository.post(url)).data;
  },
  async campioneEstesoDg(anno, idRendiconto) {
    const url = `api/ministero/udm/anno/${anno}/${idRendiconto}/campione-esteso-dg`;
    return (await Repository.get(url)).data;
  },
  async esportaSuExcelRendicontiFormali(anno, idVerbale, formBody) {
    const url = `api/ministero/udm/anno/${anno}/verbale-campionamento/${idVerbale}/rendiconti-formali/excel`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, formBody, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "rendiconti_formali_verbale_" + idVerbale + ".xlsx";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async salvaInvioRegis(anno, statoInvio, idRendiconto) {
    const url = `api/ministero/udm/anno/${anno}/${idRendiconto}/salva-invio-regis/${statoInvio}`;
    return (await Repository.post(url)).data;
  },
  async tipoControlloRendiconto(anno, idRendiconto) {
    const url = `api/ministero/udm/anno/${anno}/${idRendiconto}/tipo-controllo-rendiconto`;
    return (await Repository.get(url)).data;
  },
  async esportaSuExcelIncarichiAssegnati(anno, formBody) {
    const url = `api/ministero/udm/anno/${anno}/incarichi-assegnati/excel`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, formBody, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = `incarichi_assegnati_${anno}-${anno + 1}.xlsx`;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async downloadFileRepoRendiconti(anno, tipoFile, formData) {
    const url = `api/ministero/udm/anno/${anno}/repo-rendiconti/${tipoFile}`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, formData, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      const extension = tipoFile === 0 ? ".xlsx" : ".pdf";
      fileLink.download = `Riepilogo_rendiconti_${anno}${extension}`;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
};
