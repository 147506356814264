<template>
  <v-container>
    <v-alert v-if="!anno" type="warning">
      Attenzione! È necessario selezionare un anno accademico.
    </v-alert>
    <div v-if="anno">
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
      <v-row>
        <v-col cols="8">
          <h1>Controlli</h1>
          <p>
            Sezione in cui è possibile effettuare il controllo sul Decreto di
            concessione del finanziamento e sui rendiconti di progetto.
          </p>
        </v-col>
        <v-col cols="4"> <RiquadroProfiloRuolo /> </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card
            class="d-flex flex-column align-self-center fill-height"
            elevation="4"
          >
            <v-card-title>
              <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
              Controllo decreto di concessione al finanziamento
            </v-card-title>
            <v-card-text
              class="d-flex flex-column align-self-center fill-height"
            >
              Sezione in cui è possibile controllare il decreto di concessione
              al finanziamento.
            </v-card-text>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link class="v-btn" :to="``">
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card
            class="d-flex flex-column align-self-center fill-height"
            elevation="4"
          >
            <v-card-title>
              <v-icon class="mr-2">mdi-clipboard-text-outline</v-icon>
              Controllo rendiconto di progetto
            </v-card-title>
            <v-card-text
              class="d-flex flex-column align-self-center fill-height"
            >
              Sezione in cui è possibile controllare i rendiconti di progetto.
            </v-card-text>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                class="v-btn"
                :to="{
                  name: 'ListaVerbaliRendicontiUdm',
                  params: { anno: this.anno },
                }"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!-- seconda riga di card -->
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card
            class="d-flex flex-column align-self-center fill-height"
            elevation="4"
          >
            <v-card-title>
              <v-icon class="mr-2">mdi-clipboard-text-outline</v-icon>
              Controllo formale rendiconti di progetto
            </v-card-title>
            <v-card-text
              class="d-flex flex-column align-self-center fill-height"
            >
              Sezione in cui è possibile controllare formalmente i rendiconti di
              progetto non campionati.
            </v-card-text>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                class="v-btn"
                :to="{
                  //name: 'ListaRendicontiControlloFormaleUdm',
                  name: 'ListaVerbaliFormaleUdm',
                  params: { anno: this.anno },
                }"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card
            class="d-flex flex-column align-self-center fill-height"
            elevation="4"
          >
            <v-card-title>
              <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
              Rendiconti assegnati
            </v-card-title>
            <v-card-text
              class="d-flex flex-column align-self-center fill-height"
            >
              Sezione in cui è possibile controllare i rendiconti di progetto
              che sono stati assegnati dal Responsabile di Progetto.
            </v-card-text>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                class="v-btn"
                :to="`/scrivania/udm/controlli/rendiconti-assegnati-udm`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card
            class="d-flex flex-column align-self-center fill-height"
            elevation="4"
            v-if="
              isRoleResponsabileControllo || isRoleConsulenza || isRoleDgUdm
            "
          >
            <v-card-title>
              <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
              Incarichi assegnati
            </v-card-title>
            <v-card-text
              class="d-flex flex-column align-self-center fill-height"
            >
              Sezione in cui è possibile visualizzare gli incarichi assegnati.
            </v-card-text>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                class="v-btn"
                :to="`/scrivania/udm/controlli/incarichi-assegnati-udm`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card
            class="d-flex flex-column align-self-center fill-height"
            elevation="4"
          >
            <v-card-title>
              <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
              Repository Rendiconti
            </v-card-title>
            <v-card-text
              class="d-flex flex-column align-self-center fill-height"
            >
              Sezione in cui è possibile visualizzare i rendiconti di progetto.
            </v-card-text>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                class="v-btn"
                :to="`/scrivania/udm/controlli/repo-rendiconti-udm`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import { mapGetters, mapState } from "vuex";

export default {
  name: "controlliUdmView",
  data() {
    return {};
  },
  components: { BreadcrumbComponent, RiquadroProfiloRuolo },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: true,
            to: `/scrivania/controlli`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
      "isRoleDgUdm",
    ]),
  },
  methods: {},
};
</script>

<style scoped></style>
