<template>
  <v-container fluid>
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <v-row>
      <v-col cols="9">
        <h1>Repository rendiconti di progetto</h1>
      </v-col>
      <v-col cols="3"> <RiquadroProfiloRuolo /> </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <div v-if="loadingPage">
      <v-row class="my-2">
        <v-col cols="2">
          <v-btn text class="blue--text" @click="getFileRepoRendiconti(0)">
            <template v-if="!loadingDownload.excel">
              <v-icon left>mdi-microsoft-excel</v-icon>
              Esporta Excel
            </template>
            <v-progress-circular
              v-else
              indeterminate
              color="blue"
              size="20"
            ></v-progress-circular>
          </v-btn>
        </v-col>
        <v-col cols="2" v-if="false">
          <v-btn text class="blue--text" @click="getFileRepoRendiconti(1)">
            <template v-if="!loadingDownload.pdf">
              <v-icon left>mdi-file-pdf-box</v-icon>
              Esporta PDF
            </template>
            <v-progress-circular
              v-else
              indeterminate
              color="blue"
              size="20"
            ></v-progress-circular>
          </v-btn>
        </v-col>
      </v-row>

      <v-tabs v-model="tab">
        <v-tab>Rendiconti Formali </v-tab>
        <v-tab>Rendiconti Sostanziali</v-tab>
        <v-tab-item>
          <v-card
            elevation="5"
            v-if="rendiconti && rendiconti.length"
            :loading="loadingRendiconti"
          >
            <v-main class="pa-4">
              <v-row class="my-4">
                <v-col col="8">
                  <v-text-field
                    solo
                    v-model="ricerca"
                    append-icon="mdi-magnify"
                    label="Cerca per ID Rendiconto, CUP, nome istituzione"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headersRendiconti"
                :search="ricerca"
                :items="rendiconti"
                ><!--eslint-disable -->
                <template v-slot:item.statoTesto="{ item }">
                  <v-chip
                      class="my-2"
                      outlined
                      :color="item.statoColore"
                  >
                    {{ item.statoTesto }}
                  </v-chip>

                </template>
                <template v-slot:item.dataTrasmissione="{ item }">
                  <span v-html="item.dataTrasmissione ? getDateToString(item.dataTrasmissione) : 'Non disponibile'" />
                </template>
                <template v-slot:item.azioni="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          class="v-icon"
                          v-bind="attrs"
                          v-on="on"
                          :icon="true"
                      >
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group color="primary">
                        <v-list-item
                            class="item-menu"
                            v-for="(itemMenu, i) in menuAction"
                            :key="i"
                            :disabled="
                        controlloDisabilitazione(
                          i,
                          item,
                          item.stato,
                          item.assegnazioneControlloUdm ?? 0
                        )
                      "
                        >
                          <v-list-item-content>
                            <v-list-item-action
                                v-if="itemMenu.path"
                                @click="
                            goToNextPage(
                              itemMenu.path,
                              parseInt(item.idStrutture),
                              item.capofila,
                              parseInt(item.id),
                              itemMenu
                            )
                          "
                            >
                              {{ itemMenu.text }}
                            </v-list-item-action>
                            <v-list-item-action
                                v-else
                                @click="gestioneClickItemMenu(i, item)"
                                v-html="itemMenu.text"
                            />
                          </v-list-item-content> </v-list-item
                        >
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:item.importoRichiesto=" { item }">
                  <span v-if="item.importoRichiesto"> {{ formattaImporto(item.importoRichiesto ?? null)}}</span>
                </template>
                <template v-slot:item.importoAmmesso=" { item }">
                  <span> {{ formattaImporto(item.importoAmmesso ?? null)}}</span>
                </template>
                <template v-slot:item.importoAmmessoControlloFormale=" { item }">
                  <span> {{ formattaImporto(item.importoAmmessoControlloFormale ?? null)}}</span>
                </template><!-- eslint-enable -->
              </v-data-table>
            </v-main>
          </v-card>
          <v-card v-else class="text-center">
            <v-card-actions class="justify-center">
              <alert-component
                testo="Non sono presenti rendiconti di progetto"
                tipo-alert="info"
                :width="800"
              />
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <repo-rendiconti-sostanziale-udm-component
            :rendiconti="rendicontiSostanziali"
          ></repo-rendiconti-sostanziale-udm-component>
        </v-tab-item>
      </v-tabs>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="blue" />
    </div>
  </v-container>
</template>
<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import { mapGetters, mapState } from "vuex";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import RepoRendicontiSostanzialeUdmComponent from "@/components/Controlli/Udm/RepoRendicontiSostanzialeUdmComponent.vue";

export default {
  components: {
    RepoRendicontiSostanzialeUdmComponent,
    BreadcrumbComponent,
    AlertComponent,
    RiquadroProfiloRuolo,
  },
  name: "RepoRendicontiUdm",
  data: () => ({
    tab: null,
    ricerca: "",
    loadingDownload: {
      excel: false,
      pdf: false,
    },
    loadingPage: false,
    loadingRendiconti: false,
    verbaliControlli: [],
    rendiconti: [],
    rendicontiSostanziali: [],
    menuAction: [
      {
        text: "Dettagli rendiconto",
        path: "/scrivania/controlli/lista-rendiconti/:idStrutture/:idRendiconto/dettaglioRendiconto",
      },
      {
        text: "Dettagli checklist UDM",
        path: "",
      },
      {
        text: "Dettaglio chiarimenti UDM",
        path: "",
      },
      {
        text: "Verbale di campionamento DG controlli",
        path: "",
      },
      {
        text: "Checklist DG controlli",
        path: "",
      },
      {
        text: "Verbale di esito  DG controlli",
        path: "",
      },
      {
        text: "Dettaglio integrazioni e modifiche DG controlli",
        path: "",
      },
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/udm/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Controllo formale rendiconti",
            disabled: true,
            to: `/scrivania/controlli/nuovo-controllo`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    headersRendiconti() {
      return [
        {
          text: "ID RENDICONTO",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "CUP", sortable: false, value: "programma.cup" },
        {
          text: "NOME ISTITUZIONE",
          value: "programma.nomeIstituzione",
        },
        {
          text: "PERIODO DI RIFERIMENTO",
          sortable: false,
          value: "quadrimestre.tipologiaQuadrimestre.descrizione",
        },
        {
          text: "IMPORTO RICHIESTO",
          sortable: false,
          value: "importoRichiesto",
        },
        {
          text: "DATA TRASMISSIONE",
          sortable: false,
          value: "dataTrasmissione",
        },
        {
          text: "ESITO ORGANO DI CONTROLLO",
          sortable: false,
          value: "stato.descrizione",
        },
        { text: "STATO UDM", sortable: true, value: "statoTesto" },
        {
          text: "IMPORTO AMMESSO DG - CONTROLLI",
          sortable: false,
          value: "importoAmmesso",
        },
        {
          text: "IMPORTO AMMESSO UDM",
          sortable: true,
          value: "importoAmmessoControlloFormale",
        },
        { text: "AZIONI", sortable: false, value: "azioni" },
      ];
    },
    ...mapState(["idStrutture", "anno", "capofila", "user"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
    ]),
  },
  watch: {
    anno() {
      this.loadingPage = false;
      this.initialize();
    },
    assegnazioneEseguita() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.loadingRendiconti = true;
        this.getRendiconti(this.anno);
        this.getVerbaliControlliRendiconti(this.anno);
        this.loadingRendiconti = false;
      }
    },
    async getRendiconti(anno) {
      this.loadingRendiconti = true;
      let rendiconti =
        await ControlliUdmRepository.getListaFormaliRendicontiUdm(anno);

      let rendicontiStati = rendiconti.map((item) => {
        if (item.verificaFormaleRendiconto) {
          if (item.verificaFormaleRendiconto.dataFineControllo) {
            switch (item.verificaFormaleRendiconto.esitoControllo) {
              case "Positivo":
                item.statoTesto = "Ammesso";
                item.statoColore = "success";
                break;
              case "Negativo":
                item.statoTesto = "Non Ammesso";
                item.statoColore = "red";
                break;
              case "Parzialmente positivo":
                item.statoTesto = "Parzialmente Ammesso";
                item.statoColore = "yellow";
                break;
              default:
                item.statoTesto = "In verifica";
                item.statoColore = "blue";
                break;
            }
          } else {
            item.statoTesto = "In verifica";
            item.statoColore = "blue";
          }
        } else {
          if (item.assegnazioneControlloUdm) {
            if (item.assegnazioneControlloUdm.statoAssegnazione) {
              item.statoTesto = "Da verificare";
              item.statoColore = "blue";
            } else {
              item.statoTesto = "Assegnato";
              item.statoColore = "blue";
            }
          } else {
            item.statoTesto = "Da assegnare";
            item.statoColore = "purple";
          }
        }
        return item;
      });

      this.rendiconti = [...rendicontiStati];
      this.loadingPage = true;
      this.loadingRendiconti = false;
    },
    async getVerbaliControlliRendiconti(anno) {
      let verbaliControlli =
        await ControlliUdmRepository.getVerbaliControlliRendicontiUdm(anno);
      let rendiconti = verbaliControlli.flatMap((item) => {
        return (item.verbaleCampionamentoRendiconto || []).map((verbale) => {
          let rendiconto = verbale.rendiconto;

          if (rendiconto.verificaRendicontoUdm) {
            if (rendiconto.verificaRendicontoUdm.dataFineControllo) {
              switch (rendiconto.verificaRendicontoUdm.esitoControllo) {
                case "Positivo":
                  rendiconto.statoTesto = "Ammesso";
                  rendiconto.statoColore = "success";
                  break;
                case "Negativo":
                  rendiconto.statoTesto = "Non Ammesso";
                  rendiconto.statoColore = "red";
                  break;
                case "Parzialmente positivo":
                  rendiconto.statoTesto = "Parzialmente Ammesso";
                  rendiconto.statoColore = "yellow";
                  break;
                default:
                  rendiconto.statoTesto = "In verifica";
                  rendiconto.statoColore = "blue";
              }
            } else {
              rendiconto.statoTesto = "In verifica";
              rendiconto.statoColore = "blue";
            }
          } else if (rendiconto.assegnazioneControlloUdm) {
            if (rendiconto.assegnazioneControlloUdm.statoAssegnazione) {
              rendiconto.statoTesto = "Da verificare";
            } else {
              rendiconto.statoTesto = "Assegnato";
            }
            rendiconto.statoColore = "blue";
          } else {
            rendiconto.statoTesto = "Da assegnare";
            rendiconto.statoColore = "purple";
          }

          return rendiconto;
        });
      });
      this.rendicontiSostanziali = [...rendiconti];
      this.loadingPage = true;
    },
    getTextDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString();
      } else {
        return "";
      }
    },
    setRendicontiSelezionati(value) {
      this.rendicontiSelezionati = value;
    },
    controlloDisabilitazione(voceMenu, rendiconto) {
      let disabilitato = true;
      switch (voceMenu) {
        case 0: // dettaglio rendiconto
          disabilitato = false;
          break;

        case 1: // dettagli checklist sempre pulsante abilitato
          disabilitato = !(
            rendiconto.verificaRendiconto &&
            rendiconto.verificaRendiconto.fileVerificaCheklist
          );
          break;
        case 2: // dettaglio chiarimenti UDM
          if (
            rendiconto.richiesteChiarimentiUdm &&
            rendiconto.richiesteChiarimentiUdm.length > 0
          ) {
            disabilitato = false;
          }
          break;
        case 3: // verbale Campionamento DG
        case 4: // verbale checklist DG
        case 5: // verbale esito DG
        case 6: // richieste Integrazioni DG Controlli
          disabilitato = false;
          break;
      }
      return disabilitato;
    },
    getDateToString(date) {
      return new Date(date).toLocaleDateString();
    },
    handleChildVariables(nameEvent) {
      this.$emit(nameEvent, this.rendicontiSelezionati);
    },
    goToNextPage(pathComponent, idStrutture, capofila, idRendiconto, itemMenu) {
      if (itemMenu.text === "Dettaglio integrazioni e modifiche") {
        this.$router.push({
          name: "dettagliIntegrazioniModifiche",
          params: { idRendiconto: idRendiconto },
          query: { readOnly: true },
        });
      } else {
        let replacements = [
          { search: ":idRendiconto", replace: idRendiconto },
          { search: ":idStrutture", replace: idStrutture },
        ];
        let result = pathComponent;
        replacements.forEach((replacement) => {
          result = result.replace(replacement.search, replacement.replace);
        });
        this.$router.push({
          path: result,
        });
      }
    },
    async gestioneClickItemMenu(indexMenu, itemRendiconto) {
      if (indexMenu === 1) {
        // richiamo l'api che mi scarica il file delle checklist;
        const idFileVerificaChecklist =
          itemRendiconto.verificaFormaleRendiconto.fileVerificaCheklist.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaFormaleRendiconto.fileVerificaCheklist
            .nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaChecklist,
          nomeFile
        );
      }
      if (indexMenu === 2) {
        this.$router.push({
          name: "dettagliChiarimentiUdmFormale",
          params: { idRendiconto: itemRendiconto.id },
          query: { readOnly: true },
        });
      }
      if (indexMenu === 3) {
        // richiamo l'api che mi scarica il file del verbale campio DG;
        const idFileVerbale = itemRendiconto.idFileVerbale;
        const anno = itemRendiconto.anno;
        const nomeFile = "Verbale_Campionamento";
        await MinisteroRepository.downloadFile(anno, idFileVerbale, nomeFile);
      }
      if (indexMenu === 4) {
        // richiamo l'api che mi scarica il file delle checklist;
        const idFileVerificaChecklist =
          itemRendiconto.verificaRendiconto.fileVerificaCheklist.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaRendiconto.fileVerificaCheklist.nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaChecklist,
          nomeFile
        );
      }
      if (indexMenu === 5) {
        // richiamo l'api che mi scarica il file dell'esito verifica DG
        const idFileVerificaRendiconto =
          itemRendiconto.verificaRendiconto.fileVerificaRendiconto.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaRendiconto.fileVerificaRendiconto
            .nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaRendiconto,
          nomeFile
        );
      }
      if (indexMenu === 6) {
        this.$router.push({
          name: "dettagliIntegrazioniModifiche",
          params: { idRendiconto: itemRendiconto.id },
          query: { readOnly: true },
        });
      }
    },
    formattaImporto(importo) {
      if (importo) {
        return new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(importo);
      }
      return "";
    },
    async getFileRepoRendiconti(tipoFile) {
      if (tipoFile === 0) {
        this.loadingDownload.excel = true;
      } else {
        this.loadingDownload.pdf = true;
      }
      const formData = new FormData();
      formData.append("rendicontiFormali", JSON.stringify(this.rendiconti));
      formData.append(
        "rendicontiSostanziali",
        JSON.stringify(this.rendicontiSostanziali)
      );
      await ControlliUdmRepository.downloadFileRepoRendiconti(
        this.anno,
        tipoFile,
        formData
      );
      if (tipoFile === 0) {
        this.loadingDownload.excel = false;
      } else {
        this.loadingDownload.pdf = false;
      }
    },
  },
};
</script>
