<template>
  <v-container fluid>
    <v-main class="pa-0" v-if="selezionabili">
      <v-data-table
        :headers="headersRendiconti"
        :items="rendiconti"
        v-model="rendicontiSelezionati"
        :show-select="selezionabili"
        @input="handleChildVariables('input')"
        @keyup="handleChildVariables('keyup')"
        ><!--eslint-disable -->
        <template #header.data-table-select></template>
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
              v-if="isRoleResponsabileControllo || isRoleConsulenza"
              :value="isSelected"
              :readonly="item.assegnazioneControllo !== null"
              :disabled="item.assegnazioneControllo !== null"
              @input="select($event)"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.inviatoRegis="{ item }">
          <span v-html="item.inviatoRegis ? 'Si' : 'No'" />
        </template>
        <template v-slot:item.finale="{ item }">
          <v-chip outlined pill :color="colorTypeRendiconto(item.finale)"
                  v-html=" item.finale ? 'Finale' : 'Intermedio'"/>
        </template>
        <template v-slot:item.dataTrasmissione="{ item }">
          <span v-html="item.dataTrasmissione ? getDateToString(item.dataTrasmissione) : 'Non disponibile'" />
        </template>
        <template v-slot:item.azioni="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="v-icon"
                  v-bind="attrs"
                  v-on="on"
                  :icon="true"
              >
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item
                  class="item-menu"
                  v-for="(itemMenu, i) in menuAction"
                  :key="i"
                  :disabled="
                    controlloDisabilitazione(
                      i,
                      item,
                      item.stato,
                      item.assegnazioneControllo ?? 0
                    )
                  "
                >
                  <v-list-item-content>
                    <v-list-item-action
                      v-if="itemMenu.path"
                      @click="
                        goToNextPage(
                          itemMenu.path,
                          parseInt(item.idStrutture),
                          item.capofila,
                          parseInt(item.id),
                          itemMenu
                        )
                      "
                    >
                      {{ itemMenu.text }}
                    </v-list-item-action>
                    <v-list-item-action
                      v-else
                      @click="gestioneClickItemMenu(i, item)"
                      v-html="itemMenu.text"
                    />
                  </v-list-item-content> </v-list-item
                >
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.importoRichiesto="{ item }">
          <span>  {{ formattaImporto(item.importoRichiesto ?? null) }} </span>
        </template>
        <template v-slot:item.importoAmmesso="{ item }">
          <span> {{ formattaImporto(item.importoAmmesso ?? null) }}</span>
        </template><!--eslint-enable -->
      </v-data-table>
    </v-main>
    <v-main v-else>
      <v-data-table :headers="headersRendiconti" :items="rendiconti"
        ><!--eslint-disable -->
        <template v-slot:item.inviatoRegis="{ item }">
          <span v-html="item.inviatoRegis ? 'Si' : 'No'" />
        </template>
        <template v-slot:item.dataTrasmissione="{ item }">
          <span v-html="item.dataTrasmissione ? getDateToString(item.dataTrasmissione) : 'Non disponibile'" />
        </template>
        <template v-slot:item.finale="{ item }">
          <v-chip outlined pill :color="colorTypeRendiconto(item.finale)"
                  v-html=" item.finale ? 'Finale' : 'Intermedio'"/>
        </template>
        <template v-slot:item.importoRichiesto=" { item }">
          <span>  {{ formattaImporto(item.importoRichiesto ?? null) }} </span>
        </template>
        <template v-slot:item.importoAmmesso=" { item }">
          <span v-html="formattaImporto(item.importoAmmesso ?? null)" />
        </template>
        <!-- eslint-enable -->
      </v-data-table>
    </v-main>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";

export default {
  name: "ListaRendicontiComponent",
  components: {},
  props: {
    rendiconti: {
      type: Array,
      required: true,
    },
    selezionabili: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    assegnazioneEseguita: 0,
    rendicontiSelezionati: [],
    headersRendiconti: [
      {
        text: "ID RENDICONTO",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "CUP", value: "programma.cup" },
      {
        text: "NOME ISTITUZIONE",
        value: "programma.nomeIstituzione",
      },
      {
        text: "PERIODO DI RIFERIMENTO",
        value: "quadrimestre.tipologiaQuadrimestre.descrizione",
      },
      { text: "IMPORTO RICHIESTO", value: "importoRichiesto" },
      { text: "DATA TRASMISSIONE", value: "dataTrasmissione" },
      {
        text: "TIPOLOGIA RENDICONTO",
        value: "finale",
      },
      { text: "STATO", value: "stato.descrizione" },
      { text: "INVIATO A REGIS", value: "inviatoRegis" },
      { text: "IMPORTO AMMESSO", value: "importoAmmesso" },
      { text: "AZIONI", value: "azioni" },
    ],
    menuAction: [
      {
        text: "Dettagli rendiconto",
        path: "/scrivania/controlli/lista-rendiconti/:idStrutture/:idRendiconto/dettaglioRendiconto",
      },
      {
        text: "Verifica rendiconto",
        path: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/verifiche`,
      },
      {
        text: "Dettaglio verbale esito",
        path: "",
      },
      {
        text: "Dettagli checklist",
        path: "",
      },
      {
        text: "Dettaglio integrazioni e modifiche",
        path: "",
      },
      {
        text: "Dettaglio chiarimenti UDM",
        path: "",
      },
    ],
  }),
  computed: {
    ...mapState(["user"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
    ]),
  },
  watch: {
    assegnazioneEseguita() {
      this.initialize();
    },
  },
  methods: {
    controlloDisabilitazione(
      voceMenu,
      rendiconto,
      statoRendiconto,
      assegnazione
    ) {
      let disabilitato = true;
      let statiNonPermessi = [];

      switch (voceMenu) {
        case 0: // dettaglio rendiconto
          disabilitato = false;
          break;
        case 1: // verifica rendiconto
          statiNonPermessi = [5, 6, 7, 8, 9];
          if (!statiNonPermessi.includes(parseInt(statoRendiconto.id))) {
            disabilitato = false;
          } else {
            return true;
          }
          disabilitato = !(
            this.isRoleIncaricatoControllo &&
            assegnazione &&
            assegnazione.incaricato &&
            assegnazione.incaricato.id === this.user.id &&
            assegnazione.statoAssegnazione &&
            assegnazione.statoAssegnazione.id === 1
          );
          //Su richiesta del MUR il caricamento del file per l'accettazione dell'incarico da parte del RdC è facoltativo!
          if (
            this.isRoleResponsabileControllo &&
            assegnazione &&
            assegnazione.incaricato &&
            assegnazione.incaricato.id === this.user.id
          ) {
            disabilitato = false;
          }
          //
          if (this.isRoleConsulenza) disabilitato = false;
          break;

        case 2: // dettagli verbale
          statiNonPermessi = [1, 2, 3, 4, 5];
          if (!statiNonPermessi.includes(parseInt(statoRendiconto.id))) {
            disabilitato = false;
          }
          break;

        case 3: // dettagli checklist sempre pulsante abilitato
          disabilitato = !(
            rendiconto.verificaRendiconto &&
            rendiconto.verificaRendiconto.fileVerificaCheklist
          );
          break;
        case 4: // dettaglio integrazioni e modifiche
          //su richiesta del MUR la voce dettaglio integrazione e modifiche rimane sempre attiva
          /*if (
            rendiconto.richiesteIntegrazioniModifiche &&
            rendiconto.richiesteIntegrazioniModifiche.length > 0
          ) {*/
          disabilitato = false;
          //}
          break;

        case 5: // dettaglio chiarimenti UDM
          disabilitato = false;
          break;
      }

      return disabilitato;
    },
    getDateToString(date) {
      return new Date(date).toLocaleDateString();
    },
    handleChildVariables(nameEvent) {
      this.$emit(nameEvent, this.rendicontiSelezionati);
    },
    goToNextPage(pathComponent, idStrutture, capofila, idRendiconto, itemMenu) {
      /*if (itemMenu.text === "Dettaglio verbale esito") {
        this.$router.push({
          name: "verificaVerbaleEsitoControlliRendiconto",
          params: { idRendiconto: idRendiconto },
          query: { readOnly: true },
        });
      }*/
      if (itemMenu.text === "Dettaglio integrazioni e modifiche") {
        this.$router.push({
          name: "dettagliIntegrazioniModifiche",
          params: { idRendiconto: idRendiconto },
          query: { readOnly: true },
        });
      }
      if (itemMenu.text === "Dettaglio verbale esito") {
        this.$router.push({
          name: "verificaVerbaleEsitoControlliRendiconto",
          params: { idRendiconto: idRendiconto },
          query: { readOnly: true },
        });
      } else {
        let replacements = [
          { search: ":idRendiconto", replace: idRendiconto },
          { search: ":idStrutture", replace: idStrutture },
        ];
        let result = pathComponent;
        replacements.forEach((replacement) => {
          result = result.replace(replacement.search, replacement.replace);
        });

        this.$router.push({
          path: result,
        });
      }
    },
    async gestioneClickItemMenu(indexMenu, itemRendiconto) {
      if (indexMenu === 3) {
        // richiamo l'api che mi scarica il file delle checklist;
        const idFileVerificaChecklist =
          itemRendiconto.verificaRendiconto.fileVerificaCheklist.id;
        const anno = itemRendiconto.anno;
        const nomeFile =
          itemRendiconto.verificaRendiconto.fileVerificaCheklist.nomeOriginale;
        await MinisteroRepository.downloadFile(
          anno,
          idFileVerificaChecklist,
          nomeFile
        );
      }
      if (indexMenu === 4) {
        this.$router.push({
          name: "dettagliIntegrazioniModifiche",
          params: { idRendiconto: itemRendiconto.id },
        });
      }
      if (indexMenu === 5) {
        this.$router.push({
          name: "dettagliRisposteChiarimentiUdm",
          params: { idRendiconto: itemRendiconto.id },
          query: { readOnly: false },
        });
      }
    },
    formattaImporto(importo) {
      if (importo) {
        return new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(importo);
      }
      return "";
    },

    colorTypeRendiconto(isRendicontoFinale) {
      return isRendicontoFinale ? "primary" : "purple darken-2";
    },
  },
};
</script>
